<template>
	<div class="page-container">
		<div class="section">
			<div class="userover-view">
				<div class="view-head">
					<div class="user-info">
						<img class="pic" :src="userInfo.storeLogo" @error="imageLoadError" alt="">
						<div class="user-info-main">
							<div class="name">{{userInfo.userName}}</div>
							<div class="text">{{$t('liveplatform.data.analyseText1')}}</div>
						</div>
					</div>
					<div class="datetimes">{{$t('liveplatform.data.renewText1')}}{{ parseInt(Date.now()/1000) | parseTime('{y}-{m}-{d}')}}{{$t('liveplatform.data.renewText2')}}</div>
				</div>
				<div class="view-wrap">
					<div class="view-main">
						<div class="wrap-tit">{{$t('liveplatform.data.analyseTitle')}}</div>
						<div id="myChart" style="height: 300px;"></div>
					</div>
					<div class="view-bar">
						<div class="item">
							<div class="item-label">
								<span>{{$t('liveplatform.data.analyseName1')}}</span>
								<el-popover width="300" trigger="hover" :content="$t('liveplatform.data.analyseTips1')"
									popper-class="live-popover-tip">
									<i slot="reference" class="el-icon-question"></i>
								</el-popover>
							</div>
							<div class="item-value">{{analysisInfo.fans_count}}</div>
						</div>
						<div class="item">
							<div class="item-label">
								<span>{{$t('liveplatform.data.analyseName2')}}</span>
								<el-popover width="300" trigger="hover" :content="$t('liveplatform.data.analyseTips2')"
									popper-class="live-popover-tip">
									<i slot="reference" class="el-icon-question"></i>
								</el-popover>
							</div>
							<div class="item-value">{{analysisInfo.visit_rate}}%</div>
						</div>
						<div class="item">
							<div class="item-label">
								<span>{{$t('liveplatform.data.analyseName3')}}</span>
								<el-popover width="300" trigger="hover" :content="$t('liveplatform.data.analyseTips3')"
									popper-class="live-popover-tip">
									<i slot="reference" class="el-icon-question"></i>
								</el-popover>
							</div>
							<div class="item-value">{{analysisInfo.new_fans_count}}</div>
						</div>
						<div class="item-bottom">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import { mapGetters } from 'vuex'
	import { fetchAnalysis } from '@/api/liveplatform';
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				analysisInfo: {}
			}
		},
		mounted() {
			this.getAnalysisInfo();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorAvatar;
				e.target.style.objectFit = 'fill';
			},
			getAnalysisInfo(){
				let param = {
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				fetchAnalysis(param).then(response => {
					this.analysisInfo = response.data;
					this.initEcharts(response.data.list);
				})
			},
			initEcharts(options) {
				const dateList = options.map(function(item) {
					return item['timse'];
				});
				const valueList1 = options.map(function(item) {
					return item['of_fensi'];
				});
				const valueList2 = options.map(function(item) {
					return item['fensi'];
				});
				var myChart = echarts.init(document.getElementById('myChart'));

				// 绘制图表
				myChart.setOption({
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: this.$t('liveplatform.echarts.legendData2'),
						textStyle: {
							color: '#fff',
							fontSize: 14
						}
					},
					xAxis: {
						// type: 'time',
						data: dateList,
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: this.$t('liveplatform.echarts.legendData2')[0],
						type: 'bar',
						data: valueList1,
						color: 'rgb(229, 229, 229)'
					},{
						name: this.$t('liveplatform.echarts.legendData2')[1],
						type: 'bar',
						data: valueList2,
						color: 'rgb(244, 159, 176)'
					}]
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.section {
		border-radius: 6px;
		background-color: $--live-background-default;
	}

	.userover-view {
		min-height: 416px;
		padding-bottom: 20px;

		.view-head {
			display: flex;

			.user-info {
				width: 0;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				.pic {
					width: 90px;
					height: 90px;
					object-fit: cover;
					border-radius: 50%;
					padding: 20px;
					box-sizing: border-box;
				}

				.user-info-main {
					flex: 1;
					width: 0;
					font-size: 22px;
					line-height: 30px;
					padding: 10px 0;
					color: $--live-text-color-2;
				}
			}

			.datetimes {
				margin: 20px;
				font-size: 12px;
				color: $--live-text-color-2;
			}
		}

		.view-wrap {
			display: flex;

			.view-main {
				flex: 1;
				width: 0;
				padding: 20px;
				.wrap-tit {
					margin: 10px 0;
					line-height: 22px;
					font-size: 14px;
					color: $--live-text-color-2;
				}
			}

			.view-bar {
				width: 20%;
				min-height: 375px;
				margin: 20px 0;

				.item {
					color: $--live-text-color-2;
					padding: 17px;

					.item-label {
						font-size: 14px;
						height: 20px;
						line-height: 20px;

						.el-icon-question {
							font-size: 15px;
							color: #CCCCCC;
							cursor: pointer;
						}
					}

					.item-value {
						width: 104px;
						height: 38px;
						line-height: 38px;
						font-size: 32px;
					}
				}

				.item-bottom {
					padding: 10px 15px;

					::v-deep .el-button {
						width: 150px;
					}
				}
			}
		}
	}
</style>
